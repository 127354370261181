import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { graphql } from "gatsby"
import Header from "../components/header"
import { useQueryParam } from "gatsby-query-params"

import ReactFullpage from "@fullpage/react-fullpage"

const SEL = "custom-section"
const SECTION_SEL = `.${SEL}`

const pluginWrapper = () => {
  require("../statics/fullpage.parallax.min")
}

const Gallery = (props) => {

  const query = new URLSearchParams(props.location.search.replace('?', ''));
  const currentImage = query.get('image');

    if(typeof fullpage_api !== "undefined") {
      //eslint-disable-next-line
      fullpage_api.moveTo(currentImage);
    }

    const article = props.data.allArticles.edges[0].node

    if(!article)
      return null

    let imageCount = 1

    return (
      <main style={{backgroundColor: "#fff"}}>
        <Header {...props} nextArticle={article.nextArticle} magazine={props.data.allMagazines.nodes[0]} />

        <div className="main-content">
          <div className="gallery container-fluid">
            {typeof window !== "undefined" && (
              <ReactFullpage
                pluginWrapper = {pluginWrapper}
                parallax={true}
                parallaxKey={'bmV4dGxldmVsdWsuY29tXzM5bmNHRnlZV3hzWVhnPVl2SQ=='}
                className="pos-rel"
                sectionSelector={SECTION_SEL}
                licenseKey={"D7571E0A-B939427A-BA8E459D-AFE903BB"}
                continuousVertical={true}
                parallaxOptions={{
                  type: "reveal",
                  percentage: 62,
                  property: "translate",
                }}
                render={comp => (
                  <ReactFullpage.Wrapper>
                    {article.images && article.images.map((image, key) => {
                      if(!image.gallery) {
                        return null
                      }

                      return (
                        <div key={key} className={(parseInt(currentImage) === imageCount++) ? SEL : SEL} >
                          <div className={"fp-bg"} style={{backgroundImage: "url("+image.gallery+")"}} />

                          <AniLink className={"cross"} direction="left" bg="#fff" fade to={"/article/" + article.slug} key={key}>
                            <img src={require("../images/cross.svg")} alt=""/>
                          </AniLink>
                        </div>
                      )
                    })}
                  </ReactFullpage.Wrapper>
                )}/>
              )}
          </div>
        </div>
      </main>
    )
}

export const query = graphql`
  query($slug: String) {
    allMagazines(filter: {articles: {elemMatch: {slug: {eq: $slug}}}}) {
      nodes {
        title,
        slug
      }
    }
    allArticles(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          slug
          images {
            gallery
          }
        }
      }
    }
  }
`

export default Gallery